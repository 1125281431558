import * as React from 'react';

import { handleCookieConsent, onUsePrivacyManagerHandler } from 'lib/cookie-consent';

type PageContainerProps = React.PropsWithChildren;

function PageContainer({ children }: PageContainerProps) {
    React.useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            const listenerId = __tcfapi?.('addEventListener', 2, handleCookieConsent);

            return () => {
                if (listenerId) {
                    __tcfapi?.('removeEentListener', 2, null, listenerId);
                }
            };
        } else {
            return;
        }
    }, []);
    React.useEffect(onUsePrivacyManagerHandler, []);

    return children;
}

export { PageContainer };
