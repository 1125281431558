export enum SocialNetworkProfileBumble {
    None = '',
    Facebook = 'https://www.facebook.com/bumbleapp',
    Instagram = 'https://www.instagram.com/bumble',
    LinkedIn = 'https://www.linkedin.com/company/bumble',
    Pinterest = 'https://www.pinterest.com/bumbleapp/',
    TikTok = 'https://www.tiktok.com/@bumble',
    Twitter = 'https://twitter.com/bumble',
    YouTube = 'https://www.youtube.com/channel/UCERo8J7mug7cVcwIKaoJLww',
}
