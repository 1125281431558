import React, { useEffect, useState, ReactNode } from 'react';

import cx from 'classnames';
import { common, header } from 'components/lexemes';
import FocusTrap from 'focus-trap-react';
import { routes } from 'lib/constants-team-bumble';
import { throttle } from 'lib/throttle';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';

import Button, { ButtonColor, ButtonDesignType } from 'components/button/button';
import Icon from 'components/icon/icon';
import Link from 'components/link/link';

type Props = {
    logo?: ReactNode;
    headerMenu?: ReactNode;
    headerMobileMenu?: ReactNode;
    extraClass?: string;
    hasMobileMenuToggle?: boolean;
    onClickOpenMenu?: () => void;
};

function Header(props: Props) {
    const { logo, headerMenu, headerMobileMenu, extraClass, hasMobileMenuToggle } = props;

    const divRef = React.useRef<HTMLHeadElement>(null);
    const [isVisible, setVisible] = useState(true);
    const [isMobileMenuOpened, setMobileMenuOpen] = useState(false);
    const isMobile = useMediaQuery(MediaQueryWidth['navigation-full']);

    const [height, setHeight] = useState(0);

    const memoizedHeight = React.useMemo(() => height, [height]);
    const measureHeight = () => {
        if (divRef.current) {
            setHeight(divRef.current.clientHeight);

            document.body.style.setProperty('--header-height', `${divRef.current.clientHeight}px`);
        }
    };

    const headerClassName = cx({
        'is-visible': isVisible,
        header: true,
        [extraClass as string]: extraClass,
    });

    useEffect(() => {
        measureHeight();

        const throttledUpdate = throttle(measureHeight, 300);
        global.window.addEventListener('resize', throttledUpdate);

        return () => {
            global.window.removeEventListener('resize', throttledUpdate);
            document.body.style.removeProperty('--header-height');
        };
    }, []);

    useEffect(() => {
        let lastScrollTop = 0;
        function update() {
            const currentScrollTop = Math.max(global.window.scrollY, 0);

            if (Math.abs(lastScrollTop - currentScrollTop) <= 5) {
                return;
            }

            if (currentScrollTop > memoizedHeight) {
                if (currentScrollTop > lastScrollTop) {
                    setVisible(false);
                } else if (
                    currentScrollTop + global.window.innerHeight <
                    global.document.documentElement.scrollHeight
                ) {
                    setVisible(true);
                }
            } else {
                setVisible(true);
            }

            lastScrollTop = currentScrollTop;
        }

        const throttledUpdate = throttle(update, 300);

        global.window.addEventListener('scroll', throttledUpdate);
        global.window.addEventListener('resize', throttledUpdate);

        return () => {
            global.window.removeEventListener('scroll', throttledUpdate);
            global.window.removeEventListener('resize', throttledUpdate);
        };
    }, [memoizedHeight]);

    useEffect(() => {
        if (isMobileMenuOpened) {
            if (isMobile) {
                global.document.body.classList.toggle('is-opened-mobile-nav', true);
            } else {
                global.document.body.classList.toggle('is-opened-mobile-nav', false);
            }
        } else {
            global.document.body.classList.toggle('is-opened-mobile-nav', false);
        }
    }, [isMobile, isMobileMenuOpened]);

    return (
        <FocusTrap
            focusTrapOptions={{
                escapeDeactivates: true,
                onDeactivate: () => setMobileMenuOpen(false),
                initialFocus: false,
                allowOutsideClick: false,
            }}
            active={isMobile && isMobileMenuOpened}
        >
            <header className={headerClassName} ref={divRef}>
                <div className="header__inner">
                    <div className="header__logotype">
                        <Link
                            className="header__logotype-link"
                            href={routes.index}
                            aria-label={common.lxA11yLogoLink}
                        >
                            {logo}
                        </Link>
                    </div>
                    {/* comparing with 'false' - we don't want rendered and then removed desktop menu on mobile version */}
                    {headerMenu && isMobile === false ? (
                        <nav className="header__menu" aria-label={header.lxA11yNavigationMain}>
                            {headerMenu}
                        </nav>
                    ) : null}
                    {hasMobileMenuToggle && isMobile ? (
                        <div className="header__mobile-menu-toggle">
                            <Button
                                isCircle={true}
                                icon={
                                    <Icon
                                        name={
                                            !isMobileMenuOpened ? Icon.Name.Menu : Icon.Name.Close
                                        }
                                    />
                                }
                                color={ButtonColor.DEFAULT}
                                designType={ButtonDesignType.TERTIARY}
                                labelText={
                                    isMobileMenuOpened
                                        ? header.lxA11yNavigationMenuClose
                                        : header.lxA11yNavigationMenuOpen
                                }
                                onClick={() => {
                                    props.onClickOpenMenu?.();
                                    setMobileMenuOpen(!isMobileMenuOpened);
                                }}
                                ariaControls={isMobileMenuOpened ? 'mobile-menu' : undefined}
                            />
                        </div>
                    ) : null}
                </div>

                {isMobile && isMobileMenuOpened ? headerMobileMenu : null}
            </header>
        </FocusTrap>
    );
}

export default Header;
