import { useState, useCallback } from 'react';

import useIsomorphicLayoutEffect from 'lib/use-isomorphic-layout-effect';

// typical breakpoints - styles/_breakpoints.scss
export enum MediaQueryWidth {
    'content-max' = 1434, // max width of content with horizontal paddings. Max screen width will be 1434 or (1434 + scrollbar width)
    'desktop-lg' = 1360,
    'desktop-md' = 1024,
    'tablet-lg' = 990,
    'tablet-md' = 900,
    'navigation-full' = 780,
    'tablet-sm' = 768,
    'desktop-sm' = 640,
    'phone-lg' = 412,
}

const useMediaQuery = (width = 900) => {
    const [targetReached, setTargetReached] = useState<boolean | undefined>(undefined);

    const updateTarget = useCallback((event: MediaQueryListEvent) => {
        if (event.matches) {
            setTargetReached(true);
        } else {
            setTargetReached(false);
        }
    }, []);

    useIsomorphicLayoutEffect(() => {
        const media = global.window.matchMedia(`(max-width: ${width - 1}px)`);
        media.addListener(updateTarget);

        // Check on mount (callback is not called until a change occurs)
        if (media.matches) {
            setTargetReached(true);
        } else {
            setTargetReached(false);
        }

        return () => media.removeListener(updateTarget);
    }, [width, updateTarget]);

    return targetReached;
};

export default useMediaQuery;
