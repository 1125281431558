import React from 'react';

import cx from 'classnames';
import FocusTrap from 'focus-trap-react';

import A11yVisuallyHidden from 'components/a11y/a11y-visually-hidden';
import Icon from 'components/icon/icon';

type Option = {
    title: string;
    value: string;
    href?: string;
};

type Props = {
    onChange?: (value: string) => void;
    options: Option[];
    defaultValue?: string;
    hasMaxHeight?: boolean;
    a11y?: {
        label: string;
    };
};

const Select: React.FC<Props> = ({ onChange, options, defaultValue, hasMaxHeight, a11y }) => {
    const [value, setValue] = React.useState(() => {
        if (!defaultValue) return options[0];

        return options.find((item) => item.value === defaultValue);
    });
    const [isOpen, setOpen] = React.useState(false);
    const [direction, setDirection] = React.useState(true);

    const dropdownRef = React.useRef<HTMLDivElement>(null);
    const selectRef = React.useRef<HTMLDivElement>(null);
    const selectLabelRef = React.useRef<HTMLButtonElement>(null);

    const handleOptionClick = (item: Option) => {
        setValue(item);

        setOpen(false);
        if (onChange) {
            onChange(item.value);
        }
    };

    const getDropdownHeight = React.useCallback(() => {
        const el = dropdownRef.current;
        let dropdownHeight = 0;
        if (el) {
            el.style.display = 'block';
            dropdownHeight = el.getBoundingClientRect().height;
            el.style.display = 'none';
        }

        return dropdownHeight;
    }, [dropdownRef]);

    const handleOpen = () => {
        if (!selectLabelRef.current) return;

        const dropdownHeight = getDropdownHeight();
        const selectElementRect: DOMRect = selectLabelRef.current.getBoundingClientRect();

        const totalHeight = dropdownHeight + selectElementRect.height;

        const hasEnoughSpace = window.innerHeight - selectElementRect.top > totalHeight;

        setDirection(hasEnoughSpace);

        setOpen(!isOpen);
    };

    React.useEffect(() => {
        getDropdownHeight();
    });

    const classnames = {
        block: cx({
            select: true,
            'is-opened': isOpen,
            'direction-top': !direction,
        }),
        dropdown: cx({
            'select-dropdown': true,
            'select-dropdown--has-max-height': hasMaxHeight,
        }),
    };

    return (
        <FocusTrap
            focusTrapOptions={{
                escapeDeactivates: true,
                onDeactivate: () => setOpen(false),
                initialFocus: false,
                allowOutsideClick: true,
            }}
            active={isOpen}
        >
            <div ref={selectRef} className={classnames.block}>
                <button
                    onClick={handleOpen}
                    className="select-label"
                    ref={selectLabelRef}
                    aria-expanded={Boolean(isOpen)}
                    type="button"
                >
                    <A11yVisuallyHidden>{a11y?.label}</A11yVisuallyHidden>

                    <span className="select-label__icon-start">
                        <Icon name="generic-globe" />
                    </span>

                    <span className="select-label__text">{value?.title}</span>

                    <span className="select-label__icon-end">
                        <Icon name="generic-chevron-down" />
                    </span>
                </button>

                <div className={classnames.dropdown} ref={dropdownRef}>
                    <ul className="select-dropdown__list" role="list">
                        {options
                            ?.filter((item) => item.value !== value?.value)
                            .map((item) => {
                                const Tag = item.href ? 'a' : 'button';

                                return (
                                    <li key={item.title} className="select-dropdown__item">
                                        <Tag
                                            data-value={item.value}
                                            href={item.href}
                                            onClick={(event) => {
                                                event.preventDefault();

                                                handleOptionClick(item);
                                            }}
                                            className="select-dropdown__link"
                                            type={!item.href ? 'button' : undefined}
                                        >
                                            {item.title}
                                        </Tag>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </div>
        </FocusTrap>
    );
};

export default Select;
