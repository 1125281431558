import { acceptAllCookies } from 'lib/has-cookie-consent';

import { initialize, pageView } from 'components/google-analytics/google-analytics';

type VendorConsents = {
    consentedVendors: Array<{ _id: string }>;
};

const SCRIPT_LOAD_DELAY = 100;
const SCRIPT_FB_ID = 'fb';
const SCRIPT_G_ADS_ID = 'g_ads';
const SCRIPT_LI_ID = 'li';
const SCRIPT_PIN_ID = 'pin';
const SCRIPT_APF_ID = 'apf';
const SCRIPT_TB_ID = 'tb';
const FB_PIXEL_ID = '5fac56cd1ba05165880458ad';
const LINKEDIN_ID = '5f1b2fbeb8e05c306f2a1ed6';
const PINTEREST_ID = '5e839a38b8e05c4e491e738e';
const APPSFLYER_ID = '5f1b2fbeb8e05c30686fd745';
const TABOOLA_ID = '5fe1db28e1860427c95c6249';
const GOOGLE_ADS_ID = '61fb9e006b4922079c088d5a';

const trackingSystem = {
    fb: '/bumble-brand-assets/libraries/tracking/facebook-pixel.js',
    g_ads: '/bumble-brand-assets/libraries/tracking/google-ads.js',
    li: '/bumble-brand-assets/libraries/tracking/linkedin.js',
    pin: '/bumble-brand-assets/libraries/tracking/pinterest.js',
    tb: '/bumble-brand-assets/libraries/tracking/taboola.js',
};

const hasConsent = (vendorConsents: VendorConsents, ID: string) =>
    vendorConsents.consentedVendors.some((vendor: { _id: string }) => vendor._id === ID);

function setTrackingPlatformById(id: string) {
    const scriptId = `script-${id}`;

    // we call script on each page load, if script loaded - it won't load again
    if (document.getElementById(scriptId)) {
        return;
    }

    if (trackingSystem) {
        let script = null;
        script = document.createElement('script');
        script.id = scriptId;
        // @ts-ignore
        script.src = trackingSystem[id];
        document.body.appendChild(script);
    }
}

const getVendorsData = function () {
    __tcfapi(
        'getCustomVendorConsents',
        2,
        (vendorConsents: VendorConsents, successForConsent: boolean) => {
            if (successForConsent) {
                const hasGoogleAdsConsent = hasConsent(vendorConsents, GOOGLE_ADS_ID);
                const hasFbPixelConsent = hasConsent(vendorConsents, FB_PIXEL_ID);
                const hasLinkedinConsent = hasConsent(vendorConsents, LINKEDIN_ID);
                const hasPinterestConsent = hasConsent(vendorConsents, PINTEREST_ID);
                const hasAppsFlyerConsent = hasConsent(vendorConsents, APPSFLYER_ID);
                const hasTaboolaConsent = hasConsent(vendorConsents, TABOOLA_ID);

                if (hasAppsFlyerConsent) {
                    setTimeout(function () {
                        setTrackingPlatformById(SCRIPT_APF_ID);
                    }, SCRIPT_LOAD_DELAY);
                }

                if (hasFbPixelConsent) {
                    setTimeout(function () {
                        setTrackingPlatformById(SCRIPT_FB_ID);
                    }, SCRIPT_LOAD_DELAY);
                }

                if (hasGoogleAdsConsent) {
                    setTimeout(() => {
                        setTrackingPlatformById(SCRIPT_G_ADS_ID);

                        initialize();
                        pageView();
                    }, 100);
                }

                if (hasLinkedinConsent) {
                    setTimeout(function () {
                        setTrackingPlatformById(SCRIPT_LI_ID);
                    }, SCRIPT_LOAD_DELAY);
                }

                if (hasPinterestConsent) {
                    setTimeout(function () {
                        setTrackingPlatformById(SCRIPT_PIN_ID);
                    }, SCRIPT_LOAD_DELAY);
                }

                if (hasTaboolaConsent) {
                    setTimeout(function () {
                        setTrackingPlatformById(SCRIPT_TB_ID);
                    }, SCRIPT_LOAD_DELAY);
                }
            }
        },
    );
};

export function handleCookieConsent(
    tcData: { gdprApplies: boolean; eventStatus: string; listenerId: string },
    success: boolean,
) {
    if (success) {
        if (
            !tcData.gdprApplies &&
            (tcData.eventStatus === 'tcloaded' || tcData.eventStatus === 'cmpuishown')
        ) {
            // non-gdpr countries
            // set all cookies allowed
            // @TODO add CCPA + Brazil
            setTimeout(() => {
                initialize();
                pageView();

                setTrackingPlatformById(SCRIPT_APF_ID);
                setTrackingPlatformById(SCRIPT_FB_ID);
                setTrackingPlatformById(SCRIPT_G_ADS_ID);
                setTrackingPlatformById(SCRIPT_LI_ID);
                setTrackingPlatformById(SCRIPT_PIN_ID);
                setTrackingPlatformById(SCRIPT_TB_ID);
            }, SCRIPT_LOAD_DELAY);
        } else if (tcData.gdprApplies) {
            const cookieContainer: HTMLElement | null =
                document.querySelector('.cookie-notification');

            if (cookieContainer) {
                cookieContainer.style.display = 'none';
            }
            // we call script on each page load, if script loaded - it won't load again
            getVendorsData();
            acceptAllCookies();
        }
    }

    return tcData.listenerId;
}

function displayGDPR(event: Event) {
    event.preventDefault();

    try {
        //@ts-ignore
        window._sp_.gdpr.loadPrivacyManagerModal();
    } catch (error) {
        window.location.href = 'https://bumble.com/cookie-policy';
    }
}

function displayCCPA(event: Event) {
    event.preventDefault();

    try {
        //@ts-ignore
        window._sp_.ccpa.loadPrivacyManagerModal('708248');
    } catch (error) {
        window.location.href = 'https://bumble.com/cookie-policy';
    }
}

export const onUsePrivacyManagerHandler = () => {
    const privacyManagerGDPR = document.querySelector('.js-manage-cookies');
    const privacyManagerCCPA = document.querySelector('.js-ccpa-privacy');

    if (privacyManagerGDPR) {
        privacyManagerGDPR.addEventListener('click', displayGDPR);
    }

    if (privacyManagerCCPA) {
        privacyManagerCCPA.addEventListener('click', displayCCPA);
    }

    return () => {
        if (privacyManagerGDPR) {
            privacyManagerGDPR.removeEventListener('click', displayGDPR);
        }

        if (privacyManagerCCPA) {
            privacyManagerCCPA.removeEventListener('click', displayCCPA);
        }
    };
};
