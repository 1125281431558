import React from 'react';

import Image, { ImageProps } from 'next/image';

const ImageContainer = (props: ImageProps) => {
    return (
        <Image
            {...props}
            width={props.width}
            height={props.height}
            fill={!props.width && !props.height}
            // unoptimized={true}
            // unoptimized={src.endsWith('svg')}
        />
    );
};

export default ImageContainer;
