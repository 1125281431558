import Localization from 'lib/localization';

export default {
    get lxPageSEOTitle() {
        // check if it is server lexeme
        return 'Bumble | Date, Chat, Meet New People & Network Better';
        // return Localization.get('bumblesite@rebrand.2024.page.seo.title');
    },

    get lxPageSEOText() {
        return Localization.get('bumblesite@rebrand.2024.page.seo.text');
    },
};
