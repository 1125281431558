import Localization from 'lib/localization';

export default {
    get lxPageIndexHeroBlockTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.index.hero.block.title');
    },

    get lxPageIndexHeroBlockSubTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.date.one.block.text');
    },

    get lxPageIndexHeroBlockAction() {
        return Localization.get('bumblesite@rebrand.2024.page.date.one.block.cta-action');
    },

    get lxPageIndexOneBlockTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.index.one.block.title');
    },

    get lxPageIndexOneBlockText() {
        return Localization.get('bumblesite@rebrand.2024.page.index.one.block.text');
    },

    get lxPageIndexOneBlockAction() {
        return Localization.get('bumblesite@rebrand.2024.page.index.one.block.action');
    },

    get lxPageIndexTwoBlockTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.index.two.block.title');
    },

    get lxPageIndexTwoBlockOneItemTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.index.two.block.item.one.title');
    },

    get lxPageIndexTwoBlockOneItemText() {
        return Localization.get('bumblesite@rebrand.2024.page.index.two.block.item.one.text');
    },

    get lxPageIndexTwoBlockTwoItemTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.index.two.block.item.two.title');
    },

    get lxPageIndexTwoBlockTwoItemText() {
        return Localization.get('bumblesite@rebrand.2024.page.index.two.block.item.two.text');
    },

    get lxPageIndexTwoBlockThreeItemTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.index.two.block.item.three.title');
    },

    get lxPageIndexTwoBlockThreeItemText() {
        return Localization.get('bumblesite@rebrand.2024.page.index.two.block.item.three.text');
    },

    get lxPageIndexThreeBlockTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.index.three.block.title');
    },

    get lxPageIndexThreeBlockText() {
        return Localization.get('bumblesite@rebrand.2024.page.index.three.block.text');
    },

    get lxPageIndexThreeBlockAction() {
        return Localization.get('bumblesite@rebrand.2024.page.index.three.block.action');
    },

    get lxPageIndexFourBlockTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.index.four.block.title');
    },

    get lxPageIndexFourBlockOneItemTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.index.four.block.item.one.title');
    },

    get lxPageIndexFourBlockOneItemText() {
        return Localization.get('bumblesite@rebrand.2024.page.index.four.block.item.one.text');
    },

    get lxPageIndexFourBlockTwoItemTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.index.four.block.item.two.title');
    },

    get lxPageIndexFourBlockTwoItemText() {
        return Localization.get('bumblesite@rebrand.2024.page.index.four.block.item.two.text');
    },

    get lxPageIndexFourBlockThreeItemTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.index.four.block.item.three.title');
    },

    get lxPageIndexFourBlockThreeItemText() {
        return Localization.get('bumblesite@rebrand.2024.page.index.four.block.item.three.text');
    },
};
