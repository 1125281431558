import ReactGA from 'react-ga4';

import { GOOGLE_ANALYTICS_ACCOUNT } from 'lib/constants';
import { log } from 'lib/log';

function nativeEvent(eventData: any) {
    try {
        if (ga) {
            ga('send', 'event', eventData);
        }
    } catch (error) {
        console.log(`native support error: ${JSON.stringify(error)}`);
    }
}

export const GA4_ID = GOOGLE_ANALYTICS_ACCOUNT;

export function initialize() {
    if (process.env.USE_GTM_FOR_TRACKING) {
        return;
    }

    ReactGA.initialize(GA4_ID);
    ReactGA.set({ anonymizeIp: true, cookieExpires: 31536000, testMode: true });
}

export function pageView() {
    const page = window.location.pathname + window.location.search;

    log(`page view => ${page}`);

    if (process.env.USE_GTM_FOR_TRACKING) {
        return;
    }

    ReactGA.send({ hitType: 'pageview', page });
}

export function clickEventFromElement(event: React.MouseEvent<HTMLAnchorElement>) {
    const {
        seoCategory = '',
        seoAction = '',
        seoLabel = '',
        seoValue,
        nonInteraction = false,
    } = event.currentTarget.dataset;

    const eventData = {
        category: seoCategory,
        action: seoAction,
        label: seoLabel,
        value:
            seoValue && typeof Number(parseInt(seoValue, 10)) === 'number'
                ? parseInt(seoValue, 10)
                : 0,
        nonInteraction: Boolean(nonInteraction),
    };

    log(`Click Event data => ${JSON.stringify(eventData)}`);

    if (process.env.USE_GTM_FOR_TRACKING) {
        nativeEvent(eventData);

        return;
    }

    ReactGA.event(eventData);
}

export function clickEventfromOptions({
    seoCategory = '',
    seoAction = '',
    seoLabel = '',
    seoValue = '',
    nonInteraction = false,
}) {
    const eventData = {
        category: seoCategory,
        action: seoAction,
        label: seoLabel,
        value:
            seoValue && typeof Number(parseInt(seoValue, 10)) === 'number'
                ? parseInt(seoValue, 10)
                : 0,
        nonInteraction: Boolean(nonInteraction),
    };

    log(`Click Event data => ${JSON.stringify(eventData)}`);

    if (process.env.USE_GTM_FOR_TRACKING) {
        nativeEvent(eventData);

        return;
    }

    ReactGA.event(eventData);
}

export function clickCustomEventNamrfromOptions({
    seoName = '',
    seoCategory = '',
    seoAction = '',
    seoLabel = '',
    seoValue = '',
    nonInteraction = false,
}) {
    const eventData = {
        category: seoCategory,
        action: seoAction,
        label: seoLabel,
        value:
            seoValue && typeof Number(parseInt(seoValue, 10)) === 'number'
                ? parseInt(seoValue, 10)
                : 0,
        nonInteraction: Boolean(nonInteraction),
    };

    log(`Click Event data => ${seoName}: ${JSON.stringify(eventData)}`);

    if (process.env.USE_GTM_FOR_TRACKING) {
        nativeEvent(eventData);

        return;
    }

    ReactGA.event(seoName, eventData);
}
