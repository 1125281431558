import {
    clickCustomEventNamrfromOptions,
    clickEventfromOptions,
} from 'components/google-analytics/google-analytics';

const onClickAboutCTA = () =>
    clickEventfromOptions({
        seoCategory: 'homepage_button',
        seoAction: 'button_click',
        seoLabel: 'about',
    });

const onClickFooterSignInCTA = () =>
    clickEventfromOptions({
        seoCategory: 'homepage_button',
        seoAction: 'button_click',
        seoLabel: 'sign-in',
    });

const onClickHeaderOpenMenuCta = () =>
    clickEventfromOptions({
        seoCategory: 'homepage_button',
        seoAction: 'button_click',
        seoLabel: 'open_menu',
    });

const onMenuItemClickHandler = (label: string) =>
    clickEventfromOptions({
        seoCategory: 'homepage_button',
        seoAction: 'button_click',
        seoLabel: label,
    });

const onAppleStoreClick = () =>
    clickCustomEventNamrfromOptions({
        seoName: 'event ios-click',
        seoCategory: 'homepage_button',
        seoAction: 'button_click',
        seoLabel: 'ios_download_app',
    });

const onGoogleStoreClick = () =>
    clickCustomEventNamrfromOptions({
        seoName: 'event android-click',
        seoCategory: 'homepage_button',
        seoAction: 'button_click',
        seoLabel: 'android_download_app',
    });

const onClickDownloadNowCta = () =>
    clickCustomEventNamrfromOptions({
        seoName: 'event desktop-click',
        seoCategory: 'homepage_button',
        seoAction: 'button_click',
        seoLabel: 'scroll_to_footer',
    });

const onClickModeDateCta = () =>
    clickCustomEventNamrfromOptions({
        seoName: 'event',
        seoCategory: 'homepage_button',
        seoAction: 'button_click',
        seoLabel: 'dating_page',
    });

const onClickModeBFFCta = () =>
    clickCustomEventNamrfromOptions({
        seoName: 'event',
        seoCategory: 'homepage_button',
        seoAction: 'button_click',
        seoLabel: 'bff_page',
    });

const onClickModeBizzCta = () =>
    clickCustomEventNamrfromOptions({
        seoName: 'event',
        seoCategory: 'homepage_button',
        seoAction: 'button_click',
        seoLabel: 'bizz_page',
    });

const onClickWhatsNewDateCta = () =>
    clickCustomEventNamrfromOptions({
        seoName: 'event',
        seoCategory: 'homepage_button',
        seoAction: 'button_click',
        seoLabel: 'how_does_bumble_work',
    });

export {
    onAppleStoreClick,
    onGoogleStoreClick,
    onClickDownloadNowCta,
    onMenuItemClickHandler,
    onClickHeaderOpenMenuCta,
    onClickFooterSignInCTA,
    onClickModeDateCta,
    onClickModeBFFCta,
    onClickModeBizzCta,
    onClickWhatsNewDateCta,
    onClickAboutCTA,
};
