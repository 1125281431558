import Localization, { TrustedValue } from 'lib/localization';

export default {
    lxA11yFooter() {
        return Localization.get('bumblesite@layout.footer.a11y.label');
    },

    /**
     * Sign in
     */

    lxFooterItemLabelSignIn() {
        return Localization.get('bma_client@pages.login-password.submit');
    },

    /**
     * Legal texts
     */

    lxFooterLegalTextApple() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.Apple.legal_text');
    },

    lxFooterLegalTextGooglePlay() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.Google_play.legal_text');
    },

    /**
     * First line
     */
    lxFooterItemLabelFAQ() {
        return Localization.get('bumble_mobile_web@company.link.faq');
    },

    lxFooterItemLabelEvents() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.EVENTS');
    },

    lxFooterItemLabelContacts() {
        return Localization.get('bma_client@pages.faq.section.items.contact_us');
    },

    lxFooterItemLabelGuidelines() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.GUIDELINES');
    },

    lxFooterItemAccessibilityPolicy() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.ACCESSIBILITY_STATEMENT');
    },

    lxFooterItemLabelCareers() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.CAREERS');
    },

    lxFooterItemLabelInvestors() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.investors');
    },

    /**
     * Second line
     */
    lxFooterItemLabelMSA() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.msa');
    },

    lxFooterItemLabelNoticeAtCollection() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.NoticeAtCollection');
    },

    lxFooterItemLabelTNC() {
        // bumblesite@LAYOUT.FOOTER.TNC
        return Localization.get('bumblesite@LAYOUT.FOOTER.Terms');
    },

    lxFooterItemLabelPrivacy() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.PRIVACY_POLICY');
    },

    lxFooterItemLabelManageCookies() {
        // bumblesite@LAYOUT.FOOTER.MANAGE_COOKIES
        return Localization.get('bumblesite@LAYOUT.FOOTER.MANAGE_COOKIES.label');
    },

    /**
     * Third line
     */

    lxFooterItemLabelGuides() {
        // bumblesite@LAYOUT.FOOTER.SEASONAL_DATING_GUIDES
        return Localization.get('bumblesite@LAYOUT.FOOTER.SEASONAL_DATING_GUIDES.label');
    },

    lxFooterItemLabelWashingtonConsumerHealthDataPrivacyPolicy() {
        return Localization.get(
            'bumblesite@LAYOUT.FOOTER.Washington_Consumer_Health_Data_Privacy_Policy.label',
        );
    },

    /**
     *
     * Other lexemes
     */

    lxBlog() {
        return Localization.get('badoo_homepage@footer.blog');
    },
    lxSafetyCentre() {
        return Localization.get('badoo_homepage@footer.safety_centre');
    },
    lxHelpCentre() {
        return Localization.get('badoo_homepage@footer.help_centre');
    },
    lxTerms() {
        return Localization.get('badoo_homepage@footer.terms');
    },
    lxNoticeAtCollection() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.NoticeAtCollection');
    },
    lxPrivacy() {
        return Localization.get('badoo_homepage@footer.privacy');
    },
    lxHealthDataConsumerPrivacyPolicy() {
        return Localization.get(
            'bumblesite@LAYOUT.FOOTER.Washington_Consumer_Health_Data_Privacy_Policy.label',
        );
    },
    lxCookiesPolicy() {
        return Localization.get('badoo_safety_center@footer.legal_links.cookie_policy');
    },
    lxCookies() {
        return Localization.get('badoo_homepage@footer.cookies');
    },
    lxDoNotSell() {
        return Localization.get('bumblesite@ccpa.donotsell.cta');
    },
    lxCareers() {
        return Localization.get('badoo_homepage@footer.careers');
    },
    lxCities() {
        return Localization.get('badoo_homepage@footer.cities');
    },
    lxChat() {
        return Localization.get('badoo_homepage@footer.chat');
    },
    lxDatingConfidenceReport() {
        return Localization.get('badoo_homepage@footer.dating_confidence_report');
    },
    lxFollowUsTitle() {
        return Localization.get('badoo_homepage@footer.follow_us.title');
    },
    lxFacebook() {
        return Localization.get('badoo_homepage@footer.facebook');
    },
    lxLinkedIn() {
        return Localization.get('badoo_homepage@footer.linkedin');
    },
    lxTwitter() {
        return Localization.get('badoo_homepage@footer.twitter');
    },
    lxInstagram() {
        return Localization.get('badoo_homepage@footer.instagram');
    },
    lxDownloadTitle() {
        return Localization.get('badoo_homepage@footer.download.title');
    },
    lxLanguageTitle() {
        return Localization.get('badoo_homepage@footer.language.title');
    },
    lxDownloadCopyText() {
        return Localization.get('badoo_homepage@footer.download.copy.text', {
            __EOL__: TrustedValue.for('<br/>'),
        });
    },
    lxCopyright() {
        return Localization.get('badoo_homepage@footer.copyright');
    },
    lxSitemap() {
        return Localization.get('badoo_homepage@footer.sitemap');
    },
    lxSlaveryAct() {
        return Localization.get('badoo_homepage@footer.slavery_act');
    },
    lxPartOf() {
        return Localization.get('badoo_homepage@footer.part_of');
    },
    lxLanguageSelection() {
        return Localization.get('bumblesite@language-selector.a11y.label');
    },
};
