import { SocialNetworkProfileBumble } from 'lib/social-profiles';

import { SocialLinksItem } from 'components/social-links/social-links';

export const socialLinks: SocialLinksItem[] = [
    {
        title: 'Facebook',
        icon: 'icon-facebook',
        url: SocialNetworkProfileBumble.Facebook,
    },
    {
        title: 'Linkedin',
        icon: 'icon-linkedin',
        url: SocialNetworkProfileBumble.LinkedIn,
    },
    {
        title: 'Instagram',
        icon: 'icon-instagram',
        url: SocialNetworkProfileBumble.Instagram,
    },
    {
        title: 'Twitter',
        icon: 'icon-twitter',
        url: SocialNetworkProfileBumble.Twitter,
    },
    {
        title: 'Pinterest',
        icon: 'icon-pinterest',
        url: SocialNetworkProfileBumble.Pinterest,
    },
    {
        title: 'Youtube',
        icon: 'icon-youtube',
        url: SocialNetworkProfileBumble.YouTube,
    },
    {
        title: 'Tiktok',
        icon: 'icon-tiktok',
        url: SocialNetworkProfileBumble.TikTok,
    },
];
