import React from 'react';

import { footer } from 'components/lexemes';
import { Country } from 'lib/enums/country';
import { useGlobalProps } from 'lib/hooks/use-global-props';
import LocaleContext from 'lib/locale-context';
import { getLocaleFromParams } from 'lib/localization';
import { socialLinks } from 'lib/social-links';
import usePlatform, { PlatformType } from 'lib/use-platform';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';

import { onAppleStoreClick, onClickFooterSignInCTA, onGoogleStoreClick } from 'containers/events';

import Footer from 'components/page/footer';

function setPrivacyLinkInContainer(lang: string) {
    let url = `https://bumble.com/${lang}/privacy`;
    /**
     * currently - new page is en-us only - wait for it to be translated for other languages and change url
     */
    const newPrivacyPolicyUrl = 'https://bumble.com/privacy-policy';

    if (lang === 'en') {
        url = newPrivacyPolicyUrl;
    }

    if (lang === 'en-us') {
        url = newPrivacyPolicyUrl;
    }

    return {
        title: footer.lxPrivacy(),
        url,
    };
}

type FooterContainerProps = {
    hideLanguageSelector?: boolean;
};
export const FooterContainer: React.FC<FooterContainerProps> = ({ hideLanguageSelector }) => {
    const lang = React.useContext(LocaleContext);
    const globalProps = useGlobalProps();
    const country = globalProps?.country;
    const pathname = usePathname();
    const router = useRouter();
    const handlePathChange = (path: string, language: string) => {
        const locale = getLocaleFromParams({ lang: language });

        if (!locale) {
            return;
        }

        let windowPathName = pathname === '/' ? path : router.pathname.replace(/\[lang\]/, locale);

        if (router.asPath === router.pathname) {
            windowPathName = path + router.pathname;
        }

        // @TODO - if we use basePath in next.config.js - use it
        // : window.location.pathname.replace(pathname, path);
        return (window.location.href = windowPathName);
    };
    const platform = usePlatform();

    const signInAction =
        platform === PlatformType.ios || platform === PlatformType.android
            ? undefined
            : {
                  href: '/get-started',
                  text: footer.lxFooterItemLabelSignIn(),
              };
    return (
        <Footer
            primaryNavigation={[
                {
                    title: footer.lxFooterItemLabelFAQ(),
                    url: `https://bumble.com/${lang}/help`,
                    target: '_blank',
                },
                {
                    title: footer.lxFooterItemLabelEvents(),
                    url: 'https://bumble.events',
                    target: '_blank',
                },
                {
                    title: footer.lxFooterItemLabelContacts(),
                    url: `https://bumble.com/${lang}/contact-us`,
                },
                {
                    title: footer.lxFooterItemLabelGuidelines(),
                    url: `https://bumble.com/${lang}/guidelines`,
                    target: '_blank',
                },
            ]}
            secondaryNavigation={[
                {
                    title: footer.lxFooterItemLabelMSA(),
                    url: 'https://bumble.com/modern-slavery-act-statement',
                    target: '_blank',
                },
                {
                    title: footer.lxNoticeAtCollection(),
                    url: 'https://bumble.com/notice-at-collection',
                    target: '_blank',
                },
                {
                    title: footer.lxFooterItemAccessibilityPolicy(),
                    url: `https://bumble.com/${lang}/accessibility`,
                    target: '_blank',
                },
                {
                    title: footer.lxTerms(),
                    url: `https://bumble.com/${lang}/terms`,
                },
                setPrivacyLinkInContainer(lang),
                country === Country.USA
                    ? null
                    : {
                          title: footer.lxCookies(),
                          onClick: (event) => {
                              event.preventDefault();
                              // GDPR Privacy Manager Call - later list only GDPR countries
                              try {
                                  // @ts-ignore - describe the _sp_ object later
                                  window._sp_.gdpr.loadPrivacyManagerModal();
                              } catch (error) {
                                  window.location.href = 'https://bumble.com/cookie-policy';
                              }
                          },
                      },
                country === Country.USA
                    ? {
                          title: footer.lxDoNotSell(),
                          onClick: (event) => {
                              event.preventDefault();

                              // CCPA Privacy Manager Call
                              try {
                                  //@ts-ignore - describe the _sp_ object later
                                  window._sp_.ccpa.loadPrivacyManagerModal('708248');
                              } catch (error) {
                                  window.location.href = 'https://bumble.com/cookie-policy';
                              }
                          },
                      }
                    : null,
                {
                    title: footer.lxFooterItemLabelWashingtonConsumerHealthDataPrivacyPolicy(),
                    url: 'https://bumble.com/bumble-washington-consumer-health-data-privacy-policy',
                    target: '_blank',
                },
                {
                    title: footer.lxFooterItemLabelCareers(),
                    url: 'https://team.bumble.com',
                    target: '_blank',
                },
                {
                    title: footer.lxFooterItemLabelInvestors(),
                    url: 'https://ir.bumble.com',
                    target: '_blank',
                },
                {
                    title: footer.lxFooterItemLabelGuides(),
                    url: 'https://bumble.com/date/seasonal',
                    target: '_blank',
                },
            ]}
            socialLinks={socialLinks}
            signInAction={signInAction}
            onSignInClick={onClickFooterSignInCTA}
            onLanguageChange={(event) => handlePathChange(`/${event}`, event)}
            hideLanguageSelector={hideLanguageSelector}
            onAppleStoreClick={onAppleStoreClick}
            onGoogleStoreClick={onGoogleStoreClick}
        />
    );
};
