import common from './common';
import footer from './footer';
import header from './header';
import pageAbout from './page-about';
import pageDate from './page-date';
import pageIndex from './page-index';
import pageNotFound from './page-not-found';
import seo from './seo';

export { common, header, footer, pageIndex, pageAbout, pageDate, pageNotFound, seo };

const Lexemes = { common, header, footer, pageIndex, pageAbout, pageDate, pageNotFound, seo };

export default Lexemes;
