import Localization from 'lib/localization';

export default {
    get lxA11yOpensInNewWindow() {
        return Localization.get('mw@link.a11y.label.new-window');
    },
    get lxSkipToMainContent() {
        return Localization.get('bumblesite@layout.skip-to-main-content');
    },
    lxA11ySocialLink(social_network: string) {
        return Localization.get('bumblesite@social-links.item.a11y.label', {
            social_network,
        });
    },
    get lxA11yGooglePlay() {
        return Localization.get('bumblesite@apps-links.google-play.a11y.label');
    },
    get lxA11yAppStore() {
        return Localization.get('bumblesite@apps-links.app-store.a11y.label');
    },
    get lxA11yLogoLink() {
        return Localization.get('bumblesite@header.logo.a11y.label');
    },
    get lxA11yLogo() {
        return Localization.get('bumblesite@logo.a11y.label');
    },
    lxA11yCarouselSlide(number: number, total: number) {
        return Localization.get('bumblesite@carousel.slide.a11y.label', { number, total });
    },
    get lxA11yCarouselDescription() {
        return Localization.get('bumblesite@carousel.a11y.description');
    },
    get lxA11yCarouselNavPrev() {
        return Localization.get('bumblesite@carousel.navigation.prev.a11y.label');
    },
    get lxA11yCarouselNavNext() {
        return Localization.get('bumblesite@carousel.navigation.next.a11y.label');
    },
    get lxA11yCarouselSlideDescription() {
        return Localization.get('bumblesite@carousel.slide.a11y.description');
    },
    lxA11yCarouselDot(number: number) {
        return Localization.get('bumblesite@carousel.navigation.dot.a11y.label', { number });
    },
};
