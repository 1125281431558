import React, { FunctionComponent } from 'react';

import { common } from 'components/lexemes';

import A11yVisuallyHidden from 'components/a11y/a11y-visually-hidden';
import Icon from 'components/icon/icon';

export type SocialLinksItem = {
    title: string;
    icon: string;
    url: string;
    onClick?: () => void;
};

export type SocialLinksProps = {
    items: SocialLinksItem[];
    ariaLabel?: string;
};

const SocialLinks: FunctionComponent<SocialLinksProps> = ({ ariaLabel, items }) => {
    return (
        <ul className="social-links" role="list" aria-label={ariaLabel}>
            {items.map((link, index) => (
                <li key={index}>
                    <a
                        className="social-links__link"
                        href={link.url}
                        target="_blank"
                        rel="noreferrer"
                        onClick={link.onClick}
                    >
                        <div className="social-links__icon">
                            <Icon size="stretch" name={link.icon} />
                        </div>
                        <A11yVisuallyHidden>
                            {common.lxA11ySocialLink(link.title)}
                            {common.lxA11yOpensInNewWindow}
                        </A11yVisuallyHidden>
                    </a>
                </li>
            ))}
        </ul>
    );
};

export default SocialLinks;
