import * as React from 'react';

import { common } from 'components/lexemes';

import Button from 'components/button/button';

const SkipLink: React.FunctionComponent = () => {
    return (
        <div className="skip-link">
            <Button text={common.lxSkipToMainContent} href="#main" />
        </div>
    );
};

export default SkipLink;
