import Localization from 'lib/localization';

export default {
    get lxHeaderItemLabelShop() {
        return Localization.get('bumblesite@LAYOUT.HEADER.THE_SHOP');
    },

    get lxHeaderItemLabelApp() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.THEAPP');
    },

    get lxHeaderItemLabelAppModeDate() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.BUMBLE_DATE');
    },

    get lxHeaderItemLabelAppModeBFF() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.BUMBLE_BFF');
    },

    get lxHeaderItemLabelAppModeBizz() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.BUMBLE_BIZZ');
    },

    get lxHeaderItemLabelAmbassadors() {
        return Localization.get('bumblesite@LAYOUT.HEADER.AMBASSADORS');
    },

    get lxHeaderItemLabelEvents() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.EVENTS');
    },

    get lxHeaderItemLabelBuzz() {
        return Localization.get('bumblesite@LAYOUT.HEADER.BEEHIVE');
    },

    get lxHeaderItemLabelAbout() {
        return Localization.get('bumblesite@LAYOUT.HEADER.ABOUT');
    },

    get lxA11yNavigationMain() {
        return Localization.get('bumblesite@layout.main.a11y.label');
    },

    get lxA11yNavigationMenuOpen() {
        return Localization.get('bumblesite@header.navigation.menu.open.a11y.label');
    },
    get lxA11yNavigationMenuClose() {
        return Localization.get('bumblesite@header.navigation.menu.close.a11y.label');
    },
};
