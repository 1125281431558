import * as React from 'react';

enum PlatformType {
    android = 'android',
    ios = 'ios',
    edge = 'edge',
    ie11 = 'ie11',
}

export { PlatformType };

// images/-/moxie/new/js/components/platform-definition.js
export default function usePlatform() {
    const [platform, setPlatform] = React.useState<PlatformType | undefined>(undefined);

    React.useEffect(() => {
        const ua = global.navigator.userAgent;

        const iPad = !!ua.match(/(iPad).*OS\s([\d_]+)/);
        const iPhone = !iPad && !!ua.match(/(iPhone\sOS)\s([\d_]+)/);

        if (iPad || iPhone) {
            setPlatform(PlatformType.ios);
        }

        if (!!ua.match(/(Android)\s+([\d.]+)/) || !!ua.match(/Silk-Accelerated/)) {
            setPlatform(PlatformType.android);
        }

        if (
            /MSIE 9/i.test(global.navigator.userAgent) ||
            /rv:11.0/i.test(global.navigator.userAgent)
        ) {
            setPlatform(PlatformType.ie11);
        }

        if (/Edge\/\d./i.test(global.navigator.userAgent)) {
            setPlatform(PlatformType.edge);
        }
    }, []);

    return platform;
}
