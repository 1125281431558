import Localization from 'lib/localization';

export default {
    get lxPageAboutHeroBlockTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.about.one.block.title');
    },

    get lxPageAboutHeroBlockText() {
        return Localization.get('bumblesite@rebrand.2024.page.about.one.block.text');
    },

    get lxPageAboutTwoBlockTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.about.two.block.title');
    },

    get lxPageAboutTwoBlockOneItemTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.about.two.block.item.one.title');
    },

    get lxPageAboutTwoBlockOneItemText() {
        return Localization.get('bumblesite@rebrand.2024.page.about.two.block.item.one.text');
    },

    get lxPageAboutTwoBlockTwoItemTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.about.two.block.item.two.title');
    },

    get lxPageAboutTwoBlockTwoItemText() {
        return Localization.get('bumblesite@rebrand.2024.page.about.two.block.item.two.text');
    },

    get lxPageAboutTwoBlockThreeItemTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.about.two.block.item.three.title');
    },

    get lxPageAboutTwoBlockThreeItemText() {
        return Localization.get('bumblesite@rebrand.2024.page.about.two.block.item.three.text');
    },
};
