import Localization from 'lib/localization';

export default {
    get lxPageDateHeroBlockTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.date.one.block.title');
    },

    get lxPageDateHeroBlockText() {
        return Localization.get('bumblesite@rebrand.2024.page.date.one.block.text');
    },

    get lxPageDateHeroBlockCta() {
        return Localization.get('bumblesite@rebrand.2024.page.date.one.block.cta-action');
    },

    get lxPageDateTwoBlockTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.date.two.block.title');
    },

    get lxPageDateTwoBlockOneItemTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.date.two.block.item.one.title');
    },

    get lxPageDateTwoBlockOneItemText() {
        return Localization.get('bumblesite@rebrand.2024.page.date.two.block.item.one.text');
    },

    get lxPageDateTwoBlockTwoItemTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.date.two.block.item.two.title');
    },

    get lxPageDateTwoBlockTwoItemText() {
        return Localization.get('bumblesite@rebrand.2024.page.date.two.block.item.two.text');
    },

    get lxPageDateTwoBlockThreeItemTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.date.two.block.item.three.title');
    },

    get lxPageDateTwoBlockThreeItemText() {
        return Localization.get('bumblesite@rebrand.2024.page.date.two.block.item.three.text');
    },

    get lxPageDateTwoBlockFourItemTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.date.two.block.item.four.title');
    },

    get lxPageDateTwoBlockFourItemText() {
        return Localization.get('bumblesite@rebrand.2024.page.date.two.block.item.four.text');
    },

    get lxPageDateTwoBlockFiveItemTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.date.two.block.item.five.title');
    },

    get lxPageDateTwoBlockFiveItemText() {
        return Localization.get('bumblesite@rebrand.2024.page.date.two.block.item.five.text');
    },

    get lxPageDateTwoBlockSixItemTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.date.two.block.item.six.title');
    },

    get lxPageDateTwoBlockSixItemText() {
        return Localization.get('bumblesite@rebrand.2024.page.date.two.block.item.six.text');
    },

    get lxPageDateTwoBlockSevenItemTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.date.two.block.item.seven.title');
    },

    get lxPageDateTwoBlockSevenItemText() {
        return Localization.get('bumblesite@rebrand.2024.page.date.two.block.item.seven.text');
    },

    get lxPageDateTwoBlockEightItemTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.date.two.block.item.eight.title');
    },

    get lxPageDateTwoBlockEightItemText() {
        return Localization.get('bumblesite@rebrand.2024.page.date.two.block.item.eight.text');
    },

    get lxPageDateTwoBlockOneItemShowMoreAction() {
        return Localization.get(
            'bumblesite@rebrand.2024.page.date.two.block.item.one.showmore.action',
        );
    },

    get lxPageDateThreeBlockTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.date.three.block.title');
    },

    get lxPageDateThreeBlockOneItemTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.date.three.block.item.one.title');
    },

    get lxPageDateThreeBlockOneItemText() {
        return Localization.get('bumblesite@rebrand.2024.page.date.three.block.item.one.text');
    },

    get lxPageDateThreeBlockTwoItemTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.date.three.block.item.two.title');
    },

    get lxPageDateThreeBlockTwoItemText() {
        return Localization.get('bumblesite@rebrand.2024.page.date.three.block.item.two.text');
    },

    get lxPageDateThreeBlockThreeItemTitle() {
        return Localization.get('bumblesite@rebrand.2024.page.date.three.block.item.three.title');
    },

    get lxPageDateThreeBlockThreeItemText() {
        return Localization.get('bumblesite@rebrand.2024.page.date.three.block.item.three.text');
    },

    get lxPageDateThreeBlockFourItemTitle() {
        return 'Zero Article title - to be added';
        // return Localization.get('bumblesite@rebrand.2024.page.date.three.block.item.four.title');
    },

    get lxPageDateThreeBlockFourItemText() {
        return 'Zero Article date - to be added';
        // return Localization.get('bumblesite@rebrand.2024.page.date.three.block.item.four.text');
    },
};
