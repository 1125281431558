import Localization from 'lib/localization';

export default {
    get lxPageNotFoundTitle() {
        return Localization.get('bma_client@video_chat.error_title');
    },

    get lxPageNotFoundDescription() {
        return Localization.get('bma_client@pages.error.not-found.description');
    },

    get lxPageNotFoundAction() {
        return Localization.get('onboarding@bumble.incognito.dismiss_warning.cta.cancel');
    },
};
