import React from 'react';

import cx from 'classnames';
import { common } from 'components/lexemes';

import A11yVisuallyHidden from 'components/a11y/a11y-visually-hidden';
import Link from 'components/link/link';

enum ButtonSize {
    SMALL,
    MEDIUM,
}

export enum ButtonDesignType {
    NONE,
    PRIMARY,
    SECONDARY,
    TERTIARY,
    LINK,
}

export const mapDesignTypeToClassname: { [key in ButtonDesignType]: string } = {
    [ButtonDesignType.NONE]: '',
    [ButtonDesignType.PRIMARY]: 'button--primary',
    [ButtonDesignType.SECONDARY]: 'button--secondary',
    [ButtonDesignType.TERTIARY]: 'button--tertiary',
    [ButtonDesignType.LINK]: 'button--link',
};

const mapSizeToClassname: { [key in ButtonSize]: string } = {
    [ButtonSize.SMALL]: 'button--size-small',
    [ButtonSize.MEDIUM]: 'button--size-medium',
};

export enum ButtonColor {
    NONE,
    DEFAULT,
    INVERSE,
}

const mapColorToClassname = {
    [ButtonColor.NONE]: '',
    [ButtonColor.DEFAULT]: 'button--color-default',
    [ButtonColor.INVERSE]: 'button--color-inverse',
};

type ButtonType = React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
>['type'];

type ButtonProps = {
    icon?: React.ReactNode;
    text?: string | React.ReactNode;
    labelText?: string | React.ReactNode;
    href?: string;
    extraClass?: string;
    testId?: string;
    color?: ButtonColor;
    size?: ButtonSize;
    designType?: ButtonDesignType;
    isCircle?: boolean;
    inline?: boolean;
    target?: string;
    rel?: string;
    type?: ButtonType;
    onClick?: (() => void) | ((event: React.MouseEvent) => void);
    seo?: {
        name?: string;
        category?: string;
        action?: string;
        label?: string;
        source?: string;
    };
    ariaExpanded?: boolean;
    ariaControls?: string;
};

const defaultProps = {
    color: ButtonColor.DEFAULT,
    size: ButtonSize.MEDIUM,
    designType: ButtonDesignType.PRIMARY,
} as const;

function Button(props: ButtonProps) {
    const {
        icon,
        labelText,
        text,
        href,
        extraClass,
        testId,
        color = ButtonColor.NONE,
        size = ButtonSize.MEDIUM,
        designType = ButtonDesignType.NONE,
        isCircle,
        inline,
        onClick,
        target,
        rel,
        type = 'button',
        seo = {
            name: null,
            category: null,
            action: null,
            label: null,
            source: null,
        },
        ariaExpanded,
        ariaControls,
    } = props;

    const className = cx({
        button: true,
        'button--circle': isCircle,
        'button--inline': inline,
        [mapColorToClassname[color]]: true,
        [mapSizeToClassname[size]]: true,
        [mapDesignTypeToClassname[designType]]: true,
        [extraClass as string]: extraClass,
    });

    /**
     * https://html.spec.whatwg.org/multipage/links.html#link-type-noreferrer
     */
    let buttonRel = rel;

    if (target === '_blank') {
        buttonRel = 'noreferrer';
    }

    const hasOverrideLabel = Boolean(labelText) || undefined;

    const content = (
        <span className="button__content" aria-hidden={hasOverrideLabel}>
            {icon ? <span className="button__icon">{icon}</span> : null}
            {text ? <span className="button__text">{text}</span> : null}
        </span>
    );

    if (href) {
        return (
            <Link
                className={className}
                href={href}
                target={target}
                rel={buttonRel}
                data-seo-name={seo.name}
                data-seo-category={seo.category}
                data-seo-action={seo.action}
                data-seo-label={seo.label}
                data-source={seo.source}
                data-test={testId}
                onClick={onClick}
            >
                <A11yVisuallyHidden>{labelText?.toString()}</A11yVisuallyHidden>

                {content}

                {target === '_blank' ? (
                    <A11yVisuallyHidden>{common.lxA11yOpensInNewWindow}</A11yVisuallyHidden>
                ) : null}
            </Link>
        );
    }

    return (
        <button
            className={className}
            type={type}
            data-seo-name={seo.name}
            data-seo-category={seo.category}
            data-seo-action={seo.action}
            data-seo-label={seo.label}
            data-source={seo.source}
            data-test={testId}
            onClick={onClick}
            aria-expanded={ariaExpanded}
            aria-controls={ariaControls}
        >
            <A11yVisuallyHidden>{labelText?.toString()}</A11yVisuallyHidden>

            {content}
        </button>
    );
}
Button.defaultProps = defaultProps;
Button.color = ButtonColor;
Button.size = ButtonSize;
Button.designType = ButtonDesignType;

export default Button;
