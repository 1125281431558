export {
    onAppleStoreClick,
    onGoogleStoreClick,
    onMenuItemClickHandler,
    onClickDownloadNowCta,
    onClickHeaderOpenMenuCta,
    onClickFooterSignInCTA,
    onClickModeDateCta,
    onClickModeBFFCta,
    onClickModeBizzCta,
    onClickWhatsNewDateCta,
    onClickAboutCTA,
} from './events';
