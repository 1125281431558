import React from 'react';
export interface A11yVisuallyHiddenProps {
    /**
     * Child nodes to be rendered
     */
    children?: React.ReactNode;
    /**
     * Which tag to use for the dynamic elements
     */
    tag?: 'h1' | 'h2' | 'h3' | 'span' | 'div';
    /**
     * The DOM "id" property for the "input" element
     */
    id?: string;
    /**
     * If the string/node needs to be rendered as HTML
     * @notice It's set via the dangerouslySetInnerHTML React API
     */
    dangerous?: boolean;
    /**
     * The accessibility properties object. Used to add more a11y control over the component
     */
    a11y?: {
        /**
         * Hides the content from screen readers
         */
        ariaHidden?: boolean;
        /**
         * Announces changed text
         */
        ariaLive?: 'polite' | 'assertive';
    };
}

const defaultProps = {
    tag: 'span',
} as const;

const A11yVisuallyHidden: React.FunctionComponent<A11yVisuallyHiddenProps> = ({
    children,
    id,
    tag,
    dangerous,
    a11y,
}) => {
    const TextTag = tag as React.ElementType;

    if (children) {
        if (dangerous) {
            return (
                <TextTag
                    className="a11y-visually-hidden"
                    id={id}
                    dangerouslySetInnerHTML={{ __html: children }}
                    aria-hidden={Boolean(a11y?.ariaHidden) || undefined}
                    aria-live={a11y?.ariaLive}
                />
            );
        } else {
            return (
                <TextTag
                    className="a11y-visually-hidden"
                    id={id}
                    aria-hidden={Boolean(a11y?.ariaHidden) || undefined}
                    aria-live={a11y?.ariaLive}
                >
                    {children}
                </TextTag>
            );
        }
    }

    return null;
};

A11yVisuallyHidden.defaultProps = defaultProps;

export default A11yVisuallyHidden;
