// See mapping: https://github.bumble.dev/server/bumble/blob/master/_packages/Location/Country.php
export enum Country {
    UNKNOWN = -1,
    BRAZIL = 12,
    UK = 13,
    GERMANY = 18,
    SPAIN = 28,
    POLAND = 47,
    USA = 55,
    FRANCE = 64,
}
